import { Box, Button, Card, CardHeader, Divider } from '@mui/material';
import { BuildCircleOutlined, ErrorOutlineOutlined, CancelOutlined } from '@mui/icons-material';
import { blue, orange } from '@mui/material/colors';
import T from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NotifyIcon, NotifyTypo } from 'web-components';
import withLoadingState from '../../../../../helpers/withLoadingState';

import EditMessagesModal from './EditMessagesModal/EditMessagesModal';
import { getValueToNullableCell } from '../../../../../helpers/stringHandler';

import DatabaseTableName from '../../DatabaseTableName';
import { IrisTableNameEnum } from '../../../../../attrs/machines';

const labelAmend = 'machines.machine_details';

const ErrorCodeTypeSelectionIrisV3 = ({ data, machineId, plcType }) => {
  const { t } = useTranslation();

  const [editModal, setEditModal] = useState(false);
  const handleClose = () => setEditModal(false);

  return (
    <>
      <DatabaseTableName
        plcType={plcType}
        machineId={machineId}
        selectedTableName={IrisTableNameEnum.MESSAGES_TABLE_NAME}
      />
      <Card sx={{ p: 4, my: 2 }}>
        <CardHeader
          sx={{ p: 0, alignItems: 'flex-start', mb: 0 }}
          action={
            <Button
              variant="text"
              sx={{ p: 1.5, gap: 1, display: 'flex', height: '50px', width: '96px' }}
              onClick={() => setEditModal(true)}
            >
              <NotifyIcon iconName="edit" fontSize="medium" />
              {t(`${labelAmend}.edit`)}
            </Button>
          }
          title={
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ mt: '15px', fontSize: '16px' }}>{t(`${labelAmend}.messages.title`)}</Box>
            </Box>
          }
          titleTypographyProps={{
            fontWeight: '500',
            variant: 'Body1'
          }}
        />
        <NotifyTypo.Subtitle1 fontWeight="400">{t(`${labelAmend}.messages.subtitle`)}</NotifyTypo.Subtitle1>

        <Box sx={{ marginTop: '30px', marginBottom: '30px' }}>
          <Box>
            <NotifyTypo.InfoText fontWeight="400">
              {t(`${labelAmend}.messages.info_table.area_name`)}
            </NotifyTypo.InfoText>
            <NotifyTypo.Body1>{getValueToNullableCell(data?.area_name)}</NotifyTypo.Body1>
          </Box>
          <Box sx={{ marginTop: '30px', marginBottom: '30px' }}>
            <Divider />
          </Box>
        </Box>

        <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap={8} sx={{ mt: 5, mb: 4, maxWidth: '1200px' }}>
          <Box gridColumn="span 2">
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <CancelOutlined color="error" sx={{ fontSize: 25 }} />
              <NotifyTypo.Body1 fontWeight="500" sx={{ ml: 1 }}>
                {t(`${labelAmend}.messages.info_table.error`)}
              </NotifyTypo.Body1>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '350px' }}>
              <Box>
                <NotifyTypo.InfoText fontWeight="400">
                  {t(`${labelAmend}.messages.info_table.class_name`)}
                </NotifyTypo.InfoText>
                <NotifyTypo.Body1>{getValueToNullableCell(data?.alarm_class_name)}</NotifyTypo.Body1>
              </Box>
            </Box>
          </Box>

          <Box gridColumn="span 2">
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <ErrorOutlineOutlined sx={{ color: orange[500], fontSize: 25 }} />
              <NotifyTypo.Body1 fontWeight="500" sx={{ ml: 1 }}>
                {t(`${labelAmend}.messages.info_table.warning`)}
              </NotifyTypo.Body1>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '350px' }}>
              <Box>
                <NotifyTypo.InfoText fontWeight="400">
                  {t(`${labelAmend}.messages.info_table.class_name`)}
                </NotifyTypo.InfoText>
                <NotifyTypo.Body1>{getValueToNullableCell(data?.warning_class_name)}</NotifyTypo.Body1>
              </Box>
            </Box>
          </Box>

          <Box gridColumn="span 2">
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <BuildCircleOutlined sx={{ color: blue[500], fontSize: 25 }} />
              <NotifyTypo.Body1 fontWeight="500" sx={{ ml: 1 }}>
                {t(`${labelAmend}.messages.info_table.maintenance`)}
              </NotifyTypo.Body1>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '350px' }}>
              <Box>
                <NotifyTypo.InfoText fontWeight="400">
                  {t(`${labelAmend}.messages.info_table.class_name`)}
                </NotifyTypo.InfoText>
                <NotifyTypo.Body1>{getValueToNullableCell(data?.maintenance_class_name)}</NotifyTypo.Body1>
              </Box>
            </Box>
          </Box>
        </Box>

        <EditMessagesModal
          open={editModal}
          close={setEditModal}
          handleClose={handleClose}
          data={data}
          machineId={machineId}
        />
      </Card>
    </>
  );
};

ErrorCodeTypeSelectionIrisV3.propTypes = {
  data: T.shape({
    alarm_class_name: T.string.isRequired,
    area_name: T.string.isRequired,
    created_at: T.arrayOf(T.shape({})),
    created_by: T.arrayOf(T.shape({})),
    last_updated_by: T.arrayOf(T.shape({})),
    maintenance_class_name: T.string.isRequired,
    updated_at: T.string.isRequired,
    warning_class_name: T.string.isRequired
  }),
  machineId: T.string.isRequired,
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired
};

ErrorCodeTypeSelectionIrisV3.defaultProps = {
  data: null
};

export default withLoadingState(ErrorCodeTypeSelectionIrisV3);
